import ErrorLayout from "../components/layout/errorLayout";
import PageNotFound from "../components/404";

const NotFound = () => {
	return (
		<ErrorLayout>
			<PageNotFound />
		</ErrorLayout>
	);
};

export default NotFound;
