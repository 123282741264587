import Link from "next/link";
import { IntroductionBlockStyled } from "../../styles/blocks";
import { StandardButtonAnchor, ButtonList } from "../../styles/type/buttons";
import { H1 } from "../../styles/type/headings";
import { AssistiveText } from "../../styles/type/typography";

const PageNotFound = () => (
	<>
		<IntroductionBlockStyled>
			<H1 flush>404...</H1>
			<AssistiveText alt="true">The page you are looking for does not exist.</AssistiveText>
		</IntroductionBlockStyled>
		<ButtonList>
			<Link href="/">
				<StandardButtonAnchor>Go back and try again</StandardButtonAnchor>
			</Link>
		</ButtonList>
	</>
);

export default PageNotFound;
